import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import {
  BASE_URL,
  BIOBOOST_EXAM,
  SEMAGLUTIDE_EXAM,
  TIRZEPATIDE_EXAM,
} from '../constants';

function ThankYouPage() {
  const location = useLocation();
  const navigate = useNavigate();

  const queryParams = new URLSearchParams(location.search);
  const name = queryParams.get('name');
  const phoneNumber = queryParams.get('phoneNumber');
  const type = queryParams.get('type');

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [meetingUrl, setMeetingUrl] = useState('');

  useEffect(() => {
    const sendRequest = async () => {
      try {
        let response;

        if (type === SEMAGLUTIDE_EXAM) {
          response = await axios.post(`${BASE_URL}/request/semaglutide/exam`, {
            phoneNumber,
          });
        } else if (type === TIRZEPATIDE_EXAM) {
          response = await axios.post(`${BASE_URL}/request/tirzepatide/exam`, {
            phoneNumber,
          });
        } else if (type === BIOBOOST_EXAM) {
          response = await axios.post(`${BASE_URL}/request/bioBoost/exam`, {
            phoneNumber,
          });
        }

        setMeetingUrl(response.data.data.meeting_url);

        navigate('.', { replace: true });
      } catch (error) {
        console.error('Error sending request:', error);

        setError(
          error.response?.data?.error ||
            'Failed to process your request. Please try again later.'
        );
      } finally {
        setLoading(false);
      }
    };

    if (phoneNumber && type) {
      sendRequest();
    } else {
      setError('Missing required parameters: phone number and type.');
      setLoading(false);
    }
  }, []);

  if (loading) {
    return (
      <div className='flex items-center justify-center h-screen bg-gray-100 text-gray-700 font-sans'>
        <div className='flex flex-col items-center'>
          <div className='loader'></div>
          <p className='mt-8 text-lg'>Processing your request...</p>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className='flex items-center justify-center h-screen bg-gray-100 text-gray-700 font-sans'>
        <div className='text-center p-6 bg-white rounded-lg shadow-lg'>
          <h2 className='text-2xl font-semibold text-red-600'>Error</h2>
          <p className='mt-4'>{error}</p>
          <button
            onClick={() => window.location.reload()}
            className='mt-6 px-4 py-2 bg-red-600 text-white rounded hover:bg-red-700'
          >
            Try Again
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className='min-h-screen flex flex-col justify-between bg-gray-100 text-gray-500 font-sans'>
      <div className='max-w-screen-xl mx-auto'>
        <div className='text-center'>
          <img src='/assets/logo.png' alt='Happy Care' className='mx-auto' />
          <h1 className='text-5xl text-primaryGreen'>
            Thank You For Choosing Happy Care Meds!
          </h1>
          <p className='text-left text-md mt-10'>Hi {name},</p>
          <p className='text-left text-md mt-6'>
            Click the button below between the hours of 6am - 7pm PST, 7 Days a
            Week, to be connected to a medical provider for your Telehealth exam
            so that your prescription can be processed.
          </p>
          <div className='text-center'>
            <button
              onClick={() => (window.location.href = meetingUrl)}
              className='mt-10 px-6 py-3 bg-primaryGreen text-white text-md rounded-3xl hover:bg-opacity-90'
            >
              Connect With A Provider Now
            </button>
          </div>
        </div>
      </div>
      <div className='w-full max-w-screen-xl mx-auto text-left my-10'>
        <h2 className='text-xl font-semibold text-primaryGreen'>
          Instructions
        </h2>
        <ul className='list-disc text-md ml-6 mt-4 space-y-2'>
          <li>Accept Permissions for Microphone & video</li>
          <li>Disconnect from any Bluetooth devices</li>
          <li>Stable internet connection</li>
          <li>Noise-Free Environment</li>
          <li>
            If video/audio drops, you will receive a phone call to complete the
            evaluation
          </li>
        </ul>
      </div>
      <div className='bg-primaryGreen w-full flex justify-between items-center pl-20 text-white'>
        <div>
          <h2 className='text-6xl'>A HAPPIER, HEALTHIER YOU</h2>
          <p className='mt-4'>START YOUR WEIGHT LOSS JOURNEY TODAY!</p>
        </div>
        <div className='h-full max-w-2xl'>
          <img
            src='/assets/footer.jpg'
            alt='Placeholder'
            className='h-full w-auto object-cover'
          />
        </div>
      </div>
    </div>
  );
}

export default ThankYouPage;
