import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import { BASE_URL } from '../constants';

function SubscribeAndRedirect() {
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const subscribeAndRedirect = async () => {
      const queryParams = new URLSearchParams(location.search);
      const email = queryParams.get('email');
      const name = queryParams.get('name');

      if (email && name) {
        try {
          const response = await axios.post(`${BASE_URL}/square/subscribe`, {
            email,
            name,
          });
          const paymentLink = response.data.paymentLink;

          window.location.href = paymentLink;
        } catch (error) {
          console.error('Error creating payment link:', error);
          setError(
            error.response.data.error ||
              'Failed to create payment link. Please try again later.'
          );
          setLoading(false);
        }
      } else {
        setError('Missing required parameters: email or name.');
        setLoading(false);
      }
    };

    subscribeAndRedirect();
  }, [location]);

  return (
    <div className='flex items-center justify-center h-screen bg-gray-100 text-gray-700 font-sans'>
      {loading && !error && (
        <div className='flex flex-col items-center'>
          <div className='loader'></div>
          <p className='mt-8 text-lg'>Redirecting to payment portal...</p>
        </div>
      )}
      {error && (
        <div className='text-center p-6 bg-white rounded-lg shadow-lg'>
          <h2 className='text-2xl font-semibold text-red-600'>Error</h2>
          <p className='mt-4'>{error}</p>
          <button
            onClick={() => window.location.reload()}
            className='mt-6 px-4 py-2 bg-red-600 text-white rounded hover:bg-red-700'
          >
            Try Again
          </button>
        </div>
      )}
    </div>
  );
}

export default SubscribeAndRedirect;
